import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import useScroll from "@material-ui/core/useScrollTrigger";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      paddingTop: "4vh",
      paddingLeft: "6vw",
      flexGrow: 1,
      // backgroundColor:'white',
      transition: "",
    },
    title: {
      fontWeight: "400",
      letterSpacing: "2px",
    },
  };
});

function StickHeader() {
  const classes = useStyles();

  let show = useScroll({
    threshold: window.innerHeight,
    disableHysteresis: true,
  });

  return show ? (
    <Fade
      in={show}
      timeout={{
        enter: 1000,
      }}
    >
      <AppBar
        elevation={0}
        position={"fixed"}
        className={classes.root}
        color={""}
      >
        <Toolbar>
          <Typography variant="h4" className={classes.title}>
            YLYL.
          </Typography>
        </Toolbar>
      </AppBar>
    </Fade>
  ) : (
    <></>
  );
}

export default function () {
  const classes = useStyles();

  return (
    <>
      <AppBar
        elevation={0}
        position={"absolute"}
        className={classes.root}
        color={"transparent"}
      >
        <Toolbar>
          <Typography variant="h4" className={classes.title}>
            YLYL.
          </Typography>
        </Toolbar>
      </AppBar>
      <StickHeader />
    </>
  );
}
