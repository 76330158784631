import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/ButtonBase';
import { ReactComponent as AppleIcon } from './apple.svg';
import { ReactComponent as HUAWEIIcon } from './huawei-icon.svg';
import useImageCropCommand from './hook/useImageCropCommand';

const useStyles = makeStyles((theme) => {
  const command = useImageCropCommand();
  console.log('command : ', command);

  return {
    root: {
      width: '100vw',
      height: '100vh',
      paddingTop: '35vh',
      paddingLeft: '8vw',
      // backgroundImage: `url("https://homepage-1258241113.cos.ap-hongkong.myqcloud.com/background-big.jpg?imageMogr2${command}")`,
      backgroundImage: `url("/assets/board1.png?imageMogr2${command}")`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
    },
    title: {
      fontWeight: '300',
      letterSpacing: '2px',
      marginBottom: '10px',
    },
    subTitle: {
      fontWeight: '300',
      letterSpacing: '1px',
      marginTop: '4vh',
    },
    icon: {
      maxWidth: '35px',
      maxHeight: '35px',
    },
    buttonTitle: {
      fontWeight: '400',
      letterSpacing: '1px',
      marginTop: '15px',
    },
    button: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '2.6vw',
      padding: '10px',
      '&:hover, &$focusVisible': {
        '& $imageMarked': {
          opacity: 1,
        },
      },
    },
    buttonGroup: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '13vh',
      marginLeft: '-1vw',
    },
    imageMarked: {
      height: '100%',
      width: '100%',
      // backgroundColor: '#f7dad955',
      boxShadow: theme.shadows[2],
      borderRadius: 6,
      position: 'absolute',
      opacity: 0,
      transition: theme.transitions.create('opacity'),
    },
    appMainShot: {
      position: 'absolute',
      zIndex: 1,
      right: '26vh',
      top: '16vh',
      width: '20vw',
      height: '80vh',
      backgroundImage: 'url("main.png")',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
  };
});

export default function ({ onDownloadButtonPress = () => {} }) {
  const classes = useStyles();
  return (
    <div className={classes.root} id={'top'}>
      <Typography variant="h3" className={classes.title}>
        真正的韩式风格
      </Typography>
      <Typography variant="h3" className={classes.title}>
        就在这, 妖哩妖哩
      </Typography>
      <Typography variant="h5" className={classes.subTitle}>
        与韩国人气网店撞个满怀
      </Typography>

      <div className={classes.buttonGroup}>
        <Button
          focusRipple
          className={classes.button}
          onClick={() => {
            window.open(
              'https://apps.apple.com/cn/app/妖哩妖哩/id1591064831',
              '_blank',
            );
          }}
        >
          <AppleIcon className={classes.icon} />
          <Typography variant="h6" className={classes.buttonTitle}>
            App Store
          </Typography>
          <span className={classes.imageMarked} />
        </Button>

        <Button
          focusRipple
          className={classes.button}
          onClick={() => {
            window.open(
              'https://appgallery.cloud.huawei.com/ag/n/app/C105451507?channelId=%E5%AE%98%E7%BD%91&id=72936a044b404f6b88a550f67c78ae18&s=CB67EA687D1941C768C3571DFE500F6FA9F8CE24B6FBEDF89C652876CE28F13F&detailType=0&v=&callType=AGDLINK&installType=0000',
              '_blank',
            );
          }}
        >
          <HUAWEIIcon className={classes.icon} />
          <Typography variant="h6" className={classes.buttonTitle}>
            HUAWEI Store
          </Typography>
          <span className={classes.imageMarked} />
        </Button>
      </div>
    </div>
  );
}
