
export default function(){
    const imageHeight = 1080
    const imageWidth = 2580
    const windowHeight = window.innerHeight
    const windowWidth = window.innerWidth
    const realImageWidth = Math.round(windowWidth * imageHeight / windowHeight)
    const cha = imageWidth - realImageWidth
    const command = cha > 0 ? 
    `/cut/${imageWidth}x${imageHeight}x${cha}` 
    : ``
    return command
}