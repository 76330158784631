import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import useImageCropCommand from './hook/useImageCropCommand';

const useStyles = makeStyles((theme) => {
  const command = useImageCropCommand();
  return {
    root: ({ image }) => ({
      width: '100vw',
      height: '100vh',
      paddingTop: '40vh',
      paddingLeft: '8vw',
      // backgroundImage: `url("https://homepage-1258241113.cos.ap-hongkong.myqcloud.com/${image}.jpg?imageMogr2${command}")`,
      backgroundImage: `url("/assets/${image}?imageMogr2${command}")`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
    }),
    title: {
      fontWeight: '300',
      letterSpacing: '2px',
      marginBottom: '5px',
    },
    subTitle: {
      fontWeight: '300',
      letterSpacing: '1px',
      marginTop: '4vh',
    },
    button: {
      marginTop: '8vh',
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 30,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
    },
  };
});

export default function ({
  title,
  subTitle,
  image,
  onDownloadButtonPress = () => {},
}) {
  const classes = useStyles({ image });

  return (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.title}>
        {title}
      </Typography>

      <Typography variant="h5" className={classes.subTitle}>
        {subTitle}
      </Typography>
      <Button className={classes.button} onClick={onDownloadButtonPress}>
        Get It Now
      </Button>
    </div>
  );
}
