import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from './appbar';
import MainBoard from './mainBoard';
import Board from './board';
import Footer from './footer';
import Snackbar from '@material-ui/core/Snackbar';

function App() {
  const [snapShow, showSnap] = React.useState(false);
  const onPress = React.useCallback(() => {
    // showSnap(true);
    window.open(
      'https://apps.apple.com/cn/app/妖哩妖哩/id1591064831',
      '_blank',
    );
  }, []);

  const handleClose = React.useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    showSnap(false);
  }, []);
  return (
    <div className="App">
      <CssBaseline />
      <AppBar />
      <MainBoard onDownloadButtonPress={onPress} />
      <Board
        title={'全韩国线上搜同款'}
        subTitle={`“这个不错。在哪买？多少钱？” 拍照上传，3秒搞定！`}
        image={'board2.png'}
        onDownloadButtonPress={onPress}
      />
      <Board
        title={'不来韩国也可以逛店'}
        subTitle={'线上线下，各个品牌，都在这里'}
        image={'board3.png'}
        onDownloadButtonPress={onPress}
      />
      <Board
        title={'打折不错过'}
        subTitle={'精选当下打折单品，实时跟进'}
        image={'board4.png'}
        onDownloadButtonPress={onPress}
      />
      <Board
        title={'时尚资讯就要最新'}
        subTitle={'实时更新，抢先GET最近上新'}
        image={'board5.png'}
        onDownloadButtonPress={onPress}
      />
      <Footer onPress={onPress} />

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snapShow}
        onClose={handleClose}
        message="准备中"
        autoHideDuration={1000}
      />
    </div>
  );
}

export default App;
