import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import HUAWEIIcon from './huawei_icon.png';
import Button from '@material-ui/core/Button';
import Apple from '@material-ui/icons/Apple';
import { Link } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: 'relative',
      paddingTop: '10vh',
      paddingLeft: '6vw',
      paddingRight: '6vw',
      height: '105vh',
      flexGrow: 1,
      background:
        'linear-gradient(to right, #BE93C5, #7BC6CC)' /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */,
    },
    row1: {
      display: 'flex',
      width: '100%',
      height: '80%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      fontSize: '36px',
      maxWidth: '55vw',
      fontWeight: '300',
      letterSpacing: '1px',
      lineHeight: '12vh',
      marginBottom: '7vh',
      color: '#fff',
    },
    icon: {
      width: '35px',
      height: '35px',
    },
    button: {
      borderRadius: 30,
    },
    leftButton: {
      marginRight: '1vw',
    },
    rightButton: {
      // marginLeft:'3vw'
    },
    buttonGroup: {
      display: 'flex',
      flexDirection: 'row',
      width: '70%',
      marginTop: '4vh',
      // backgroundColor:'#000',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    subTitle: {
      fontSize: '36px',
      fontWeight: '300',
      letterSpacing: '1px',
      color: '#fff',
    },
    row2: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      position: 'absolute',
      width: '100%',
      left: 0,
      bottom: 0,
      height: '15vh',
      backgroundColor: 'rgba(0,0,0,.3)',
    },
    row2Title: {
      color: '#fff',
      fontSize: '13px',
      fontWeight: '400',
    },
  };
});

export default function ({ onPress = () => {} }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.row1}>
        <Typography className={classes.title} variant={'h4'}>
          "
          我们致力于让中国的小姐姐们像韩国的欧尼一样以合理的价格享受真正的韩国优质时尚资源。"
        </Typography>
        <hr width="70%" color="#fff" size="1" />

        <div className={classes.buttonGroup}>
          <Typography className={classes.subTitle}>用时尚，丰富生活</Typography>

          <div>
            <Button
              variant="outlined"
              size="large"
              className={[classes.button, classes.leftButton]}
              onClick={() => {
                window.open(
                  'https://apps.apple.com/cn/app/妖哩妖哩/id1591064831',
                  '_blank',
                );
              }}
              startIcon={
                <Apple className={classes.icon} style={{ fontSize: 35 }} />
              }
            >
              App Store
            </Button>

            <Button
              variant="outlined"
              color="secondary"
              size="large"
              onClick={() => {
                window.open(
                  'https://appgallery.cloud.huawei.com/ag/n/app/C105451507?channelId=%E5%AE%98%E7%BD%91&id=72936a044b404f6b88a550f67c78ae18&s=CB67EA687D1941C768C3571DFE500F6FA9F8CE24B6FBEDF89C652876CE28F13F&detailType=0&v=&callType=AGDLINK&installType=0000',
                  '_blank',
                );
              }}
              className={[classes.button, classes.rightButton]}
              startIcon={<img src={HUAWEIIcon} className={classes.icon} />}
            >
              HUAWEI Store
            </Button>
          </div>
        </div>
      </div>

      <div className={classes.row2}>
        <Typography className={classes.row2Title}>
          Copyright © 2023 安顺市盛鸿黔电子商务有限公司 All rights reserved.
        </Typography>
        <Link href='https://beian.miit.gov.cn/' underline='always'>
          <Typography className={classes.row2Title}>
            黔ICP备2021000913号-1
          </Typography>
        </Link>
      </div>
    </div>
  );
}
